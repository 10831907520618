import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { LoginService } from 'app/_services/login.service';
import { LicensingService } from 'app/_services/licensing.service';
import { CommunicationService } from 'app/_services/communication.service';
import { ErrorController } from 'app/_controllers/errorController';

@Component({
  selector: 'app-login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  //#region Fields 
  showPassword: boolean = false;
  type: string = 'password';
  changePassword: boolean = false;
  username: string;
  passwordStrength: string;
  cameFromSettings: boolean = false;
  translationsLoaded: boolean = false;
  //#endregion 

  constructor(
    private app: AppComponent,
    private loginService: LoginService,
    private errorController: ErrorController,
    private licensingService: LicensingService,
    private communicationService: CommunicationService
  ) { }

  ngOnInit(): void {
    this.app.getTranslations();
    this.communicationService.app_base_or_login_translationsLoaded$.subscribe(() => {
      this.translationsLoaded = true;
    });

    if (sessionStorage.getItem('resetPassword') != undefined && sessionStorage.getItem('resetPassword').toString() == 'true') {
      this.changePassword = true;
      this.cameFromSettings = true;      
      this.username = this.app.cookieService.get('loggedIn').split('|')[2];
    }
  }

  back(): void {
    if (this.cameFromSettings) {
      this.app.router.navigate(['base']);
      return;
    }

    this.changePassword = false;    
  }

  validatePassword(newPassword: string): void {
    if (newPassword.length < 8) {
      this.passwordStrength = 'password-weak';
      return;
    }

    let uppercase = /[A-Z]/;
    let lowercase = /[a-z]/;
    let number = /[0-9]/;
    let special = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

    if (uppercase.test(newPassword) && lowercase.test(newPassword) && number.test(newPassword)) {
      this.passwordStrength = special.test(newPassword) ? 'password-strong' : 'password-medium';
    }
  }

  forgotPassword(username: string): void {
    if (!username) {
      this.app.alertError(this.app.translations.ALERT_Error_UsernameRequired);
      return;
    }

    this.username = username;
    this.changePassword = true;
  }

  resetPassword(currentPassword: string, newPassword: string, confirmPassword: string): void {
    if (currentPassword.length == 0) {
      this.app.alertError(this.app.translations.ALERT_Error_CurrentPasswordRequired);
      return;
    }

    if (newPassword.length == 0) {
      this.app.alertError(this.app.translations.ALERT_Error_NewPasswordRequired);
      return;
    }

    if (confirmPassword.length == 0) {
      this.app.alertError(this.app.translations.ALERT_Error_ConfirmPassword);
      return;
    }

    if (confirmPassword.length > 0 && confirmPassword != newPassword) {
      this.app.alertError(this.app.translations.ALERT_Error_PasswordMisMatch);
      return;
    }

    if (this.passwordStrength == 'password-weak') {
      this.app.alertError(this.app.translations.ALERT_Error_WeakPassword);
      return;
    }

    let keys = ['username', 'currentPassword', 'newPassword'];
    let values = [this.username, currentPassword, newPassword];
    let formData = this.app.buildForm(keys, values);
    this.loginService.resetPassword(formData).subscribe({
      next: (data: { result: boolean }) => {
        if (data.result) {
          this.login(this.username, newPassword);
        }
      }, error: (errorLog) => {
        this.errorController.logError(errorLog, 'loginComponent.resetPassword() > loginService.resetPassword()');
      }
    })
  }

  login(username: string, password: string): void {
    if (username.length == 0) {
      this.app.alertError(this.app.translations.ALERT_Error_UsernameRequired);
      return;
    }

    if (password.length == 0) {
      this.app.alertError(this.app.translations.ALERT_Error_PasswordRequired);
      return;
    }

    let formData = this.app.buildForm(['username', 'password'], [username, password]);
    this.loginService.loginUser(formData).subscribe({
      next: (data: { id: number, passwordExpired: string }) => {
        if (data) {
          if (data.passwordExpired == 'EXPIRED') {
            this.username = username;
            this.app.alertInfo(this.app.translations.ALERT_Info_PasswordExpired);
            this.changePassword = true;
            return;
          }

          this.createSession(data.id, username, data.passwordExpired);
        } else {
          this.app.alertError(this.app.translations.ALERT_Error_WrongCredentials);
        }
      }, error: (errorLog) => {
        this.errorController.logError(errorLog, 'loginComponent.login() > loginService.loginUser()');
      }
    });
  }

  createSession(userId: number, username: string, passwordExpired: string): void {
    let stationId = localStorage.getItem('stationId');
    let formData = this.app.buildForm(['userId', 'stationId'], [userId.toString(), stationId]);
    this.licensingService.createSession(formData).subscribe({
      next: (data: { result: boolean, message: string, sessionId: string, stationId: string }) => {
        if (data.result) {
          this.app.cookieService.set('loggedIn', `true|${userId}|${username}`, 7, '/', '', false);
          this.app.getTranslations();//reload translations with logged in user id
          localStorage.setItem('sessionId', data.sessionId);
          localStorage.setItem('stationId', data.stationId);

          if (sessionStorage.getItem('directReportURL') != undefined) {
            this.app.router.navigate(['viewer']);
          }

          if (passwordExpired == 'OK') {
            this.app.router.navigate(['base']);
          }

          if (passwordExpired != 'EXPIRED' && passwordExpired != 'OK' && passwordExpired != 'Note') {
            this.app.alertInfo(passwordExpired);
            setTimeout(() => this.app.router.navigate(['base']), 2000);
            return;
          }
        } else {
          this.app.alertError(data.message);
        }
      }, error: (errorLog) => {
        this.errorController.logError(errorLog, 'loginComponent.createSession() > licensingService.createSession()');
      }
    })
  }
}
