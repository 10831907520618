import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CommunicationService } from 'app/_services/communication.service';
import { GanttService } from 'app/_services/gantt.service';
import { AppComponent } from 'app/app.component';
import { ErrorController } from 'app/_controllers/errorController';
import { DxGanttComponent } from 'devextreme-angular';
import { IGanttTask } from 'app/_models/IGanttTask';
import { IAction } from 'app/_models/IAction';
import { IGanttDependency } from 'app/_models/IGanttDependency';
import { ISourceViewHeader } from 'app/_models/ISourceViewHeader';
import { ILookupValues } from 'app/_models/ILookupValues';
import { IGanttData } from 'app/_models/IGanttData';
import { ICompiledLine } from 'app/_models/ICompiledLine';

@Component({
  selector: 'app-gantt',
  templateUrl: './gantt.component.html',
  styleUrls: ['./gantt.component.css']
})
export class GanttComponent implements OnInit {
  //#region Fields
  @Input() darkMode: boolean;
  @Input() globalFontSize: number;
  @Input() tablePadding: number;
  @Input() userId: string;
  @Input() username: string;
  nodeId: string;
  ganttData: IGanttData;
  keyValue: string;  
  compiledTasks: IGanttTask[] = [];
  compiledLine: ICompiledLine[] = [];
  ganttTasks: IGanttTask[] = [];
  ganttDependencies: IGanttDependency[] = [];
  headers: ISourceViewHeader[] = [];
  projectData: ILookupValues[] = [];
  @ViewChild('ganttChart', { static: false }) ganttChart: DxGanttComponent;
  //#endregion

  constructor(
    public app: AppComponent,
    private communicationService: CommunicationService,
    private ganttService: GanttService,
    private errorController: ErrorController
  ) {
    this.communicationService.base_gantt_loadGantt$.subscribe(() => {
      this.loadGantt();
    })
  }

  ngOnInit(): void {    
    this.nodeId = sessionStorage.getItem('nodeId');
    this.communicationService.loadRadButtons([], 'Projects');
    this.loadProjects();
  }

  loadGantt(): void {
    let keys = ['nodeId', 'userId', 'viewName', 'keyValue'];
    let values = [this.nodeId, this.userId, 'Gant', this.keyValue];
    let formData = this.app.buildForm(keys, values);
    this.ganttService.getData(formData).subscribe({
      next: (data: IGanttData) => {
        this.app.hideLoader();
        this.ganttTasks = data.tasks;
        this.ganttDependencies = data.dependencies;
        this.headers = data.headers;
        this.communicationService.updateRadHeaders(this.headers);
      }, error: (errorLog) => {
        this.errorController.logError(errorLog, 'ganttComponent.loadGantt() > ganttService.getData()');
      }
    });
  }

  loadProjects(): void {
    let keys = ['nodeId', 'userId', 'viewName', 'keyValue'];
    let values = [this.nodeId, this.userId, 'Projects', this.keyValue];
    let formData = this.app.buildForm(keys, values);
    this.ganttService.getData(formData).subscribe({
      next: (data: ILookupValues[]) => {
        this.app.hideLoader();
        this.projectData = data;
        let projectIdFromUrl = sessionStorage.getItem('directLineId');
        if (projectIdFromUrl) {          
          sessionStorage.removeItem('directLineId'); 
          sessionStorage.removeItem('directedNodeId'); 
          this.selectValue(undefined, projectIdFromUrl);
        }
      }, error: (errorLog) => {
        this.errorController.logError(errorLog, 'ganttComponent.loadGantt() > ganttService.getData()');
      }
    });
  }

  selectValue(event: any, projectIdFromUrl: string): void {
    this.keyValue = event == undefined ? projectIdFromUrl : event.srcElement.value.toString();       
    this.compiledTasks = [];
    this.compiledLine = [];
    this.compiledLine.push({ id: this.keyValue, lineData: null, lineNumber: null });
    this.app.showLoader();
    this.loadGantt();
    this.communicationService.filterRadButtons('Projects', this.compiledLine);
  }

  selectTask(event: any): void {
    if (event.key != 0 || event.key != '') {
      this.ganttTasks.forEach(task => task.color = task.originalColor);
      var task = this.ganttTasks.find(tsk => tsk.id == event.key);
      if (task) {
        var dependency = this.ganttDependencies.find(dependency => dependency.successorId == task.id);
        if (dependency) task.pred = dependency.predecessorId.toString();

        task.selected = !task.selected;
        task.color = task.selected ? task.color = '#72b92b' : task.originalColor;
        this.compiledTasks = [];
        if (task.selected) this.compiledTasks.push(task);

        this.communicationService.filterRadButtons(task.selected ? 'Gant' : 'Projects', this.compiledTasks.length > 0 ? this.compiledTasks : this.compiledLine);
        if (this.ganttChart && this.ganttChart.instance) this.ganttChart.instance.refresh();
      }
    }
  }
}
