import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, throwError } from 'rxjs';
import { header } from 'app/_misc/headers';

@Injectable({ providedIn: 'root' })
export class LoginService {
  constructor(private httpClient: HttpClient) { }      

  loginUser(formData: FormData) {
    return this.httpClient.post('/login/login', formData, { 
      headers: new header().FormHeader, observe: 'response' 
    }).pipe(map(response => { 
      return response.body; 
    }), catchError(this.handleError));
  }

  validateLoginCookie(formData: FormData) {
    return this.httpClient.post('/login/validatelogincookie', formData, {
      headers: new header().FormHeader, observe: 'response' 
    }).pipe(map(response => { 
      return response.body;
    }), catchError(this.handleError));
  }  

  resetPassword(formData: FormData) {
    return this.httpClient.post('/login/resetpassword', formData, { 
      headers: new header().FormHeader, observe: 'response' 
    }).pipe(map(response => { 
      return response.body; 
    }), catchError(this.handleError));
  }

  handleError(error: any) {    
    return throwError(error);
  }
}