<p-dialog [visible]="true" [breakpoints]="{'430px': '100%'}" [style]="{width: '75%'}" [baseZIndex]="10000"
  [ngClass]="{'change-scrollbar-color-darkMode': darkMode, 'change-scrollbar-color': !darkMode}" [modal]="true"
  [maximizable]="false" [draggable]="false" [resizable]="false">
  <div class="row sticky dialog-title-container" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
    <div class="col-12 unset-p">
      <div class="flex relative align-centre dialog-title">
        <span class="textGreen font16 b-radius dialog-title-icon" [ngClass]="darkMode ? 'bg-dark-secondary' : 'bg-light-blue'">
          <i class="fa-solid fa-gear"></i>
        </span>
        <span class="font17"
          [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{app.translations.GBL_lbl_SettingsText}}</span>
        <div class="absolute" style="right: 15px;">
          <span class="textGreen font16 b-radius dialog-title-icon cursorPointer" (click)="close()"
            [ngClass]="darkMode ? 'bg-dark-secondary' : 'bg-light-blue'">
            <i class="fa-solid fa-xmark"></i>
          </span>          
        </div>
      </div>
    </div>
  </div>
  <div class="row" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
    <div class="col-2">
      <div class="font15 cursorPointer animated slideInLeft padding7" (click)="selectItem(1)">
        <i class="fa-solid fa-sliders menu-icon" [ngClass]="darkMode ? 'textWhite' : 'textBlack'"></i>
        <span class="menuItemText padding3 b-radius" 
          [ngClass]="selectedItem == 1 ? (darkMode ? 'textWhite bg-dark-secondary' : 'textBlack bg-light-blue') : (darkMode ? 'textWhite' : 'textBlack')">
          {{app.translations.SET_lbl_PersonalisationText}}
        </span>
      </div>
      <div class="font15 cursorPointer animated slideInLeft padding7" (click)="selectItem(2)">
        <i class="fa-solid fa-border-all menu-icon" [ngClass]="darkMode ? 'textWhite' : 'textBlack'"></i>
        <span class="menuItemText padding3 b-radius" 
          [ngClass]="selectedItem == 2 ? (darkMode ? 'textWhite bg-dark-secondary' : 'textBlack bg-light-blue') : (darkMode ? 'textWhite' : 'textBlack')">
          {{app.translations.SET_lbl_GridsText}}
        </span>
      </div>
      <div class="font15 cursorPointer animated slideInLeft padding7" (click)="selectItem(3)">
        <i class="fa-regular fa-user menu-icon" [ngClass]="darkMode ? 'textWhite' : 'textBlack'"></i>
        <span class="menuItemText padding3 b-radius" 
          [ngClass]="selectedItem == 3 ? (darkMode ? 'textWhite bg-dark-secondary' : 'textBlack bg-light-blue') : (darkMode ? 'textWhite' : 'textBlack')">
          {{app.translations.SET_lbl_AccountText}}
        </span>
      </div>
    </div>
    <div class="col-10">
      <div class="settings-container" [ngClass]="darkMode ? 'side-border-dark' : 'side-border'">
        <ng-template [ngIf]="selectedItem == 1 && settingsLoaded">
          <div class="col-12 animated fadeInDown">
            <div class="setting" [ngClass]="darkMode ? 'setting-border-dark' : 'setting-border'">
              <span class="font16 bold"
                [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{app.translations.SET_lbl_DarkModeText}}</span><br><br>
              <span class="font15" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
                {{app.translations.SET_lbl_DarkModeSettingText}}
              </span>
              <mat-slide-toggle class="float-right" [(ngModel)]="darkModeSetting.value"
                (ngModelChange)="updateDarkModeSetting($event)"></mat-slide-toggle><br><br>
              <span class="font15" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
                {{app.translations.SET_lbl_DarkModeMatchSettingText}}
              </span>
              <mat-checkbox class="float-right" [checked]="followSystemDarkMode" (change)="followSystem($event)">
              </mat-checkbox>
            </div>
          </div>
          <div class="col-12 animated fadeInDown">
            <div class="setting" [ngClass]="darkMode ? 'setting-border-dark' : 'setting-border'">
              <span class="font16 bold"
                [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{app.translations.SET_lbl_MenuText}}</span><br><br>
              <span class="font15" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
                {{app.translations.SET_lbl_toggleExpandMenuText}}
              </span>
              <mat-slide-toggle class="float-right" [(ngModel)]="menuSetting.value"
                (ngModelChange)="updateMenuSetting($event)"></mat-slide-toggle>
            </div>
          </div>
          <div class="col-12 animated fadeInDown">
            <div class="setting" [ngClass]="darkMode ? 'setting-border-dark' : 'setting-border'">
              <span class="font16 bold"
                [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{app.translations.SET_lbl_TextsizeText}}</span><br><br>
              <span class="" [ngClass]="['font' + textSizeSetting.value, darkMode ? 'textWhite' : 'textBlack']">
                {{app.translations.SET_lbl_TextsizeSettingText}}
              </span>
              <input type="number" min="10" max="22" [(ngModel)]="textSizeSetting.value" step="1" pattern="[0-9]*"
                class="font13 float-right unset-outline b-radius padding3 unset-b"
                (ngModelChange)="updateTextSizeSetting($event)" [ngClass]="darkMode ? 'textWhite bg-dark-secondary' : 'textBlack bg-light-blue'" />
            </div>
          </div>
        </ng-template>
        <ng-template [ngIf]="selectedItem == 2">
          <div class="col-12 animated fadeInDown">
            <div class="setting" [ngClass]="darkMode ? 'setting-border-dark' : 'setting-border'">
              <span class="font16 bold"
                [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{app.translations.SET_lbl_CompactViewText}}</span><br><br>
              <span class="font15" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
                {{app.translations.SET_lbl_toggleCompactText}}
              </span>
              <mat-slide-toggle class="float-right" [(ngModel)]="compactGridSetting.value"
                (ngModelChange)="updateCompactGridSetting($event)"></mat-slide-toggle>
            </div>
          </div>
          <div class="col-12 animated fadeInDown">
            <div class="setting" [ngClass]="darkMode ? 'setting-border-dark' : 'setting-border'">
              <span class="font16 bold"
                [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{app.translations.SET_lbl_FilterSettingTextTitle}}</span><br><br>
              <span class="font15" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
                {{app.translations.SET_lbl_FilterSettingText}}
              </span>
              <mat-slide-toggle class="float-right" [(ngModel)]="filterSetting.value"
                (ngModelChange)="updateFilterSetting($event)"></mat-slide-toggle>
            </div>
          </div>
        </ng-template>
        <ng-template [ngIf]="selectedItem == 3">
          <div class="col-12 animated fadeInDown">
            <div class="setting" [ngClass]="darkMode ? 'setting-border-dark' : 'setting-border'">
              <span class="font16 bold" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">Change Password</span><br><br>
              <span class="font15" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
                <button class="textGreen bg-none unset-b unset-p" (click)="changePassword()">
                  {{app.translations.SET_btn_ChangePasswordText}}
                </button> {{app.translations.SET_btn_ChangePasswordSettingText}}
              </span>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</p-dialog>