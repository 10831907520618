import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { Router } from '@angular/router';

//#region Components
import { AppComponent } from './app.component';
import { LoginComponent } from './login-component/login.component';
import { GridComponent } from './grid-component/grid.component';
import { MenuComponent } from './menu-component/menu.component';
import { DetailGridComponent } from './detail-grid-component/detail-grid.component';
import { Direct } from './_misc/direct';
import { FormActionComponent } from './form-action-component/form-action.component';
import { ColumnComponent } from './column-component/column.component';
import { FeedbackComponent } from './feedback-component/feedback.component';
import { ErrorComponent } from './error-component/error.component';
import { SettingsComponent } from './settings-component/settings.component';
import { NotificationComponent } from './notification-component/notification.component';
import { DashboardComponent } from './dashboard-component/dashboard.component';
import { GridActionComponent } from './grid-action-component/grid-action.component';
import { ImageViewerComponent } from './image-viewer-component/image-viewer.component';
import { ReportComponent } from './report-component/report.component';
import { UserMenuComponent } from './user-menu-component/user-menu.component';
import { RadComponent } from './rad-component/rad.component';
import { BaseComponent } from './base-component/base.component';
import { GanttComponent } from './gantt-component/gantt.component';
import { SqlActionComponent } from './sql-action-component/sql-action.component';
import { ImportComponent } from './import-component/import.component';
//#endregion

//#region Modules
import { DxReportViewerModule, DxReportDesignerModule } from 'devexpress-reporting-angular';
import { DxDashboardControlModule } from 'devexpress-dashboard-angular';
import { DxGanttModule } from 'devextreme-angular';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClipboardModule } from 'ngx-clipboard';
import { AccordionModule } from 'primeng/accordion';
import { SliderModule } from 'primeng/slider';
import { PasswordModule } from 'primeng/password';
import { DropdownModule } from 'primeng/dropdown';
import { ScrollTopModule } from 'primeng/scrolltop';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { NgChartsModule } from 'ng2-charts';
import { CalendarModule } from 'primeng/calendar';
import { TabViewModule } from 'primeng/tabview';
//#endregion

//#region Error Handler
import { ErrorService } from 'app/_services/error.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler implements ErrorHandler {
  constructor(    
    private router: Router,    
    private errorService: ErrorService
  ) { }

  handleError(error: any) {
    console.error(error);
    console.log(`Caught from global error handler: ${error}`);

    if (sessionStorage.getItem('errorRefNumber')) {
      this.router.navigate(["error"]);
      return;
    }

    let nodeId = -1;
    if (sessionStorage.getItem('nodeId')) {
      nodeId = Number(sessionStorage.getItem('nodeId')!.toString());
    }

    let userId = -1;
    if (sessionStorage.getItem('userId')) {
      userId = Number(sessionStorage.getItem('userId')!.toString());
    }

    let nodeName = 'Unknown View';
    if (sessionStorage.getItem('nodeName')) {
      nodeName = sessionStorage.getItem('nodeName')!.toString();
    }

    console.log(`UserId: ${userId}`);
    console.log(`NodeId: ${nodeId}`);
    console.log(`Path: ${nodeName} > Angular Operation/Arithmetic Error`);    

    let formData = new FormData();
    formData.append('userId', encodeURIComponent(userId));
    formData.append('nodeId', encodeURIComponent(nodeId));
    formData.append('nodeName', encodeURIComponent(nodeId));
    formData.append('functionName', encodeURIComponent('Angular Operation/Arithmetic Error'));
    formData.append('error', encodeURIComponent(error.message.toString()));
    this.errorService.logError(formData).subscribe({
      next: (data: any) => {
        sessionStorage.setItem('errorRefNumber', data.result);
        sessionStorage.setItem('error', error);
        this.router.navigate(["error"]);        
      }
    });
  }
}
//#endregion

@NgModule({
  declarations: [
    AppComponent,    
    LoginComponent,
    GridComponent,
    MenuComponent,
    DetailGridComponent,
    FormActionComponent,
    ColumnComponent,
    FeedbackComponent,
    ErrorComponent,
    SettingsComponent,
    NotificationComponent,
    DashboardComponent,
    GridActionComponent,
    ImageViewerComponent,
    ReportComponent,
    UserMenuComponent,
    RadComponent,
    BaseComponent,
    GanttComponent,
    SqlActionComponent,
    ImportComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    DxReportViewerModule,
    DxReportDesignerModule,
    DxDashboardControlModule,
    DxGanttModule,
    TableModule,
    ButtonModule,
    DialogModule,
    TooltipModule,
    InputTextareaModule,
    CheckboxModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    ClipboardModule,
    AccordionModule,
    SliderModule,
    PasswordModule,
    DropdownModule,
    ScrollTopModule,
    TabViewModule,
    CalendarModule,
    BrowserAnimationsModule,
    NgChartsModule,
    NgbModule,
    OverlayPanelModule,
    RouterModule.forRoot([
      { path: '', component: BaseComponent, pathMatch: 'full' },
      { path: 'base', component: BaseComponent },
      { path: 'grid', component: GridComponent },
      { path: 'gantt', component: GanttComponent },
      { path: 'signin', component: LoginComponent },
      { path: 'direct', component: Direct },
      { path: 'error', component: ErrorComponent },
      { path: 'report-designer', component: ReportComponent },
      { path: 'report-viewer', component: ReportComponent },            
      { path: 'dashboard-designer', component: DashboardComponent },
      { path: 'dashboard-viewer', component: DashboardComponent },
    ])
  ],
  providers: [
    AppComponent,
    GridComponent,
    MenuComponent,
    DetailGridComponent,
    ColumnComponent,
    FormActionComponent,
    FeedbackComponent,
    SqlActionComponent,
    Direct,    
    SettingsComponent,
    LoginComponent,
    NotificationComponent,
    DashboardComponent,
    GridActionComponent,
    ImageViewerComponent,
    DatePipe,
    ReportComponent,
    UserMenuComponent,
    RadComponent,
    BaseComponent,
    GanttComponent,
    ImportComponent,
    ErrorComponent,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
