import { Injectable, NgZone } from '@angular/core';
import { AppComponent } from 'app/app.component';
import { LicensingService } from './licensing.service';
import { ErrorController } from 'app/_controllers/errorController';
import { Subscription, interval } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InactivityService {
  private activityEvents = ['mousemove', 'click'];
  private timeoutId: any;
  sessionValidationSubject: Subscription;

  constructor(
    private ngZone: NgZone,
    public app: AppComponent,
    private licensingService: LicensingService,
    private errorController: ErrorController
  ) {
    this.startMonitoring();
    this.resetTimeout();

    this.sessionValidationSubject = interval(600000).subscribe(() => {
      this.validateSession();
    });
  }

  startMonitoring(): void {
    this.activityEvents.forEach(event => {
      window.addEventListener(event, this.resetTimeout.bind(this));
    });
  }

  stopMonitoring(): void {
    this.activityEvents.forEach(event => {
      window.removeEventListener(event, this.resetTimeout.bind(this));
    });
    this.clearTimeout();
  }

  resetTimeout(): void {    
    this.clearTimeout();
    this.ngZone.runOutsideAngular(() => {
      this.timeoutId = window.setTimeout(() => {
        this.ngZone.run(() => this.logout());
      }, 600000);
    });
  }

  clearTimeout(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  logout(): void {
    this.stopMonitoring();
    this.terminateSession();
    sessionStorage.clear();
    this.app.cookieService.delete('loggedIn');    
    window.location.href = '/signin';
  }

  validateSession(): void {
    let formData = this.app.buildForm(['sessionId'], [localStorage.getItem('sessionId')])
    this.licensingService.validateSession(formData).subscribe({
      next: (data: { result: boolean, message: string }) => {        
        if (!data.result) {
          this.sessionValidationSubject.unsubscribe();
          this.logout();
        }
      }, error: (errorLog) => {
        this.errorController.logError(errorLog, 'baseComponent.validateSession() > licensingService.validateSession()');
      }
    })
  }

  terminateSession(): void {
    let sessionId = Number(localStorage.getItem('sessionId'));
    this.sessionValidationSubject.unsubscribe();
    if (sessionId > 0) {
      localStorage.removeItem('sessionId');
      let formData = this.app.buildForm(['sessionId'], [sessionId.toString()]);      
      this.licensingService.terminateSession(formData).subscribe({
        error: (errorLog) => {
          this.errorController.logError(errorLog, 'baseComponent.terminateSession() > licensingService.terminateSession()');
        }
      })
    }
  }
}
