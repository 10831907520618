import { Component, HostListener, Input } from '@angular/core';
import { AppComponent } from 'app/app.component';
import { CommunicationService } from 'app/_services/communication.service';

@Component({
  selector: 'feedback-component',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent {
  @Input() darkMode: boolean; 
  @Input() feedbackMessage: string;   
  
  constructor(
    public app: AppComponent,    
    private communicationService: CommunicationService
  ) { }

  close(): void { this.communicationService.closeFeedbackDialog(); }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Escape' || event.key === 'Esc') this.close();
  }
}
